import React, { useState } from 'react';
import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  SelectChangeEvent,
} from '@mui/material';

interface YearDropdownProps {
  onYearChange?: (year: string) => void;
  initialValue?: string;
}

const YearDropdown: React.FC<YearDropdownProps> = ({ onYearChange, initialValue }) => {
  const [selectedYear, setSelectedYear] = useState(initialValue);

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedYear(event.target.value as string);
    if (onYearChange) {
      onYearChange(event.target.value as string);
    }
  };
  return (
    <FormControl fullWidth>
      <InputLabel id="year-select-label">Year</InputLabel>
      <Select
        labelId="year-select-label"
        id="year-select"
        value={selectedYear}
        label="Year"
        onChange={handleChange}
        sx={{ width: 150, height: 40 }}
      >
        <MenuItem value={'2022-2023'}>2022-2023</MenuItem>
        <MenuItem value={'2023-2024'}>2023-2024</MenuItem>
        <MenuItem value={'2024-2025'}>2024-2025</MenuItem>
      </Select>
    </FormControl>
  );
};

export default YearDropdown;
