import React, { useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import {
  AppBar,
  Box,
  IconButton,
  Typography,
  Avatar,
  MenuItem,
  Menu,
  Modal,
  Button,
  ListItemIcon,
  Grid,
} from '@mui/material';
import {
  Search, NotificationsNone, KeyboardArrowDown, Person, Logout,
} from '@mui/icons-material';
// import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Image, Loader } from '../components';
import YearDropdown from '../utils/YearDropdown';
interface ConfirmationModalProps {
  open: boolean;
  onClose: () => void;
  onConfirmLogout: () => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  open,
  onClose,
  onConfirmLogout,
}) => (
  <Modal
    open={open}
    onClose={onClose}
    aria-labelledby="logout-confirmation-modal"
    aria-describedby="logout-confirmation-modal"
  >
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 296,
        bgcolor: 'background.paper',
        borderRadius: 3,
        p: 4,
      }}
    >
      <Typography variant="h6" id="logout-confirmation-modal" component="div" align="center" fontWeight="600" color="#000000">
        Are you sure you want to logout?
      </Typography>
      <Box sx={{
        display: 'flex', justifyContent: 'center', mt: 2, gap: '8px',
      }}
      >
        <Button
          variant="outlined"
          sx={{
            mr: 1, backgroundColor: 'white', textTransform: 'none', color: '#2559C3', borderColor: '#2559C3',
          }}
          onClick={onClose}
        >
          Go back
        </Button>
        <Button
          variant="contained"
          sx={{
            mr: 1, backgroundColor: '#2559C3', textTransform: 'none', color: '#fff',
          }}
          onClick={onConfirmLogout}
        >
          Logout
        </Button>
      </Box>
    </Box>
  </Modal>
);

interface Props {
  onYearChange?: (year: string) => void;
  currentFinancialYear?: string;
}

const Header: React.FC<Props> = ({ onYearChange, currentFinancialYear }) => {
  // const user = useSelector((state: any) => state.user.employee);
  // const { firstName, profile } = user;
  const location = useLocation();
  const isAppraisalOrReports = location.pathname === '/appraisal' || location.pathname === '/reports';
  const navigate = useNavigate();
  const fullName = `PMS ADMIN`;
  const profile = { department: 'Admin', designation: 'Software Engineer' };
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const hanldeChangePassowrd = () => {
      navigate("/chnage-password");  
  }

  const handleLogout = () => {
    handleClose();
    setShowConfirmation(true);
  };

  const handleConfirmLogout = () => {
    // signOut(auth);
    localStorage.clear();
    setShowConfirmation(false);
    navigate("/");  
  };

  const handleModalClose = () => {
    setShowConfirmation(false);
  };
  return (
    <AppBar
      position="fixed"
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: '#fff',
        padding: '10px',
        boxShadow: 'none',
        borderBottom: '1px solid #EEEEEE',
      }}
    >
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
       <Image src="image 5.png" />
        </Grid>

        <Grid container item xs={6} justifyContent="flex-end" alignItems="center" spacing={2}>
          <Grid item>
            {isAppraisalOrReports && (
              <YearDropdown onYearChange={onYearChange} initialValue={currentFinancialYear} />
            )}
          </Grid>
          <Grid item>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <Avatar alt="Profile Picture" src="Ellipse 2.png" />
              </Grid>
              <Grid item>
                <Typography
                  sx={{
                    fontWeight: 900,
                    fontSize: '14px',
                    lineHeight: '19px',
                    color: '#000000',
                    fontFamily: 'Lato',
                  }}
                >
                  {fullName}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: '500',
                    fontSize: '12px',
                    lineHeight: '16px',
                    color: '#2559C3',
                  }}
                >
                  {profile?.department}
                </Typography>
              </Grid>
              <Grid item>
                <IconButton
                  size="large"
                  edge="end"
                  aria-label="account of current user"
                  aria-haspopup="true"
                  color="inherit"
                  onClick={handleClick}
                >
                  <KeyboardArrowDown style={{ color: '#2559C3' }} />
                </IconButton>
                <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                  <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                      <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                  </MenuItem>
                  <MenuItem onClick={hanldeChangePassowrd}>
                    <ListItemIcon>
                      <Logout fontSize="small" />
                    </ListItemIcon>
                    Change Password
                  </MenuItem>
                </Menu>
                <ConfirmationModal
                  open={showConfirmation}
                  onClose={handleModalClose}
                  onConfirmLogout={handleConfirmLogout}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AppBar>
  );
};

export default Header;
